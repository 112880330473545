<template>
  <v-card outlined>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="component.name"
            type="text"
            name="name"
            label="Name"
            required
            @change="component.setSlug(component.slug || component.name)"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="component.slug"
            type="text"
            name="slug"
            label="Slug"
            required
            :disabled="$route.params.id && !dev"
            @change="component.setSlug(component.slug)"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="component.description"
            type="textarea"
            name="description"
            label="Description"
            counter="280"
            max="280"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="component.image"
            type="text"
            name="image"
            label="Image"
            append-outer-icon="mdi-folder-multiple-image"
            @click:append-outer="dialog.file = true"
          />

          <vx-img rounded rectangle :src="$getImage(component.image, 400)" />
        </v-col>
      </v-row>
    </v-card-text>

    <AddFile v-model="dialog.file" @resolve="onResolveFile" @reject="dialog.file = false" />
  </v-card>
</template>

<script>
import AddFile from '@admin/dialogs/add-file';

export default {
  components: { AddFile },

  props: {
    component: { type: Object, required: true },
  },

  data() {
    return {
      dialog: {
        file: false,
      },
    };
  },

  methods: {
    onResolveFile(file) {
      this.component.image = file.url;
      this.dialog.file = false;
    },
  },
};
</script>
