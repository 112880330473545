var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.component.setSlug(_vm.component.slug || _vm.component.name);
      }
    },
    model: {
      value: _vm.component.name,
      callback: function callback($$v) {
        _vm.$set(_vm.component, "name", $$v);
      },
      expression: "component.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.component.setSlug(_vm.component.slug);
      }
    },
    model: {
      value: _vm.component.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.component, "slug", $$v);
      },
      expression: "component.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "textarea",
      "name": "description",
      "label": "Description",
      "counter": "280",
      "max": "280"
    },
    model: {
      value: _vm.component.description,
      callback: function callback($$v) {
        _vm.$set(_vm.component, "description", $$v);
      },
      expression: "component.description"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "image",
      "label": "Image",
      "append-outer-icon": "mdi-folder-multiple-image"
    },
    on: {
      "click:append-outer": function clickAppendOuter($event) {
        _vm.dialog.file = true;
      }
    },
    model: {
      value: _vm.component.image,
      callback: function callback($$v) {
        _vm.$set(_vm.component, "image", $$v);
      },
      expression: "component.image"
    }
  }), _c('vx-img', {
    attrs: {
      "rounded": "",
      "rectangle": "",
      "src": _vm.$getImage(_vm.component.image, 400)
    }
  })], 1)], 1)], 1), _c('AddFile', {
    on: {
      "resolve": _vm.onResolveFile,
      "reject": function reject($event) {
        _vm.dialog.file = false;
      }
    },
    model: {
      value: _vm.dialog.file,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "file", $$v);
      },
      expression: "dialog.file"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }