<template>
  <v-form ref="form" lazy-validation>
    <v-card v-if="created">
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs">
          <v-tab>Basic</v-tab>
          <v-tab>Fields</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Basic :component="component" />
          </v-tab-item>

          <v-tab-item>
            <Fields
              :component="component"
              :collections="collections"
              :total-collection="totalCollection"
              @loadMoreCollections="getMoreCollections"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { ComponentSchemaService, CollectionSchemaService } from '@tutti/services';
import { ComponentSchema } from '@tutti/models';
import Basic from './basic';
import Fields from './fields';

export default {
  name: 'LandingPageEdit',

  components: { Basic, Fields },

  data() {
    return {
      tab: 0,
      loading: false,
      created: false,
      component: new ComponentSchema(),
      collections: [],
      collectionParams: {
        page: 1,
        limit: 24,
      },
      totalCollection: 0,
    };
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getComponent();
    await this.getCollections();
    this.created = true;
  },

  methods: {
    async getComponent() {
      if (this.$route.params.id) {
        const response = await ComponentSchemaService.getByIdAdmin(this.$route.params.id);

        if (response) {
          this.component = new ComponentSchema(response.data);
          this.$setTitle(this.component.name);
        }
      }
    },

    async getCollections() {
      const paramsData = {
        option: { wantPagination: true },
        ...this.collectionParams,
      };

      const response = await CollectionSchemaService.getAll(paramsData);

      if (response) {
        this.totalCollection = response.data?.count;
        this.collections = [...this.collections, ...response.data.data];
      }
    },

    async save(previous) {
      this.loading = true;
      const response = await ComponentSchemaService.save(this.component.get());
      if (response && previous) {
        this.$previous();
      }
      this.loading = false;
    },

    async getMoreCollections(searchString = undefined) {
      this.collectionParams.page += 1;
      if (searchString) {
        this.collections = [];
        this.collectionParams.page = 1;
      }
      await this.getCollections();
    },
  },
};
</script>
